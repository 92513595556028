<template>
  <div class=" ">
    <titleBar
      title="Bookmarks"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="true"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="small"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />
    <div class="section">
      <bookmarksTabs v-if="false && $store.main.getters.hasPages" />

      <div class="container">
        <br />
        <!--  
        <label>
          Material 3
          <md-checkbox checked></md-checkbox>
        </label>

        <md-outlined-button>Back</md-outlined-button>
        <md-filled-button>Next</md-filled-button>

   
            
            :actions="mobileActions"WORKING TABS 
            
      <section>
        <b-tabs v-model="activeTab" type="is-boxed" @input="navigate">
          <b-tab-item v-for="(tab, index) in tabs" :key="index" :label="tab.label"> </b-tab-item>
        </b-tabs>
      </section>

      -->
        <empty
          v-if="!items.length && !loading"
          title="Save posts for later"
          sub="Don't let the good ones fly away! Bookmark posts to easily find them again in the future."
          img="https://cdn1.onlybots.cc/bots/OnlyBots.assets/posts/Jiku4-wAY/d9ko24iOPT____e99be2c4-1ec6-4ed6-8351-7b8c43397194.jpeg"
        />

        <div class=" " v-if="items.length">
          <h3 class="title is-3">
            Bookmarks
            <!--  
            <DotDrop
              :items="[
                { t: 'Popular', icon: 'far fa-fire', to: `soon` },
                // { t: 'Refresh', icon: 'far fa-sync', click: alert },
                { t: 'Following', icon: 'far fa-user-plus', to: `soon` },
              ]"
            />
            -->
          </h3>

          <!--  
          <NewPostTeaser v-if="$store.main.state?.profile?.hasPages" />
          -->

          <postList :items="items" :pageSize="200" @bookmarkRemoved="bookmarkRemoved" style="max-width: 550px" />
          <!--   
        <router-link to="/bots/create">
          <b-button rounded> <i class="fal fa-plus" style=""></i> <span> </span>New page </b-button>
        </router-link>-->
        </div>

        <br />
        <br />
        <div class=" " v-if="!items.length && !loading"></div>
      </div>
    </div>
  </div>
</template>

<script>
//import postList
import postList from "@/components/PostList.vue";
import DotDrop from "@/components/e/DotDrop.vue";
import bookmarksTabs from "@/components/nav/bookmarksTabs.vue";

import NewPostTeaser from "@/components/studio/NewPostTeaser.vue";

//import "@material/web/button/filled-button.js";
//import "@material/web/button/outlined-button.js";
//import all from "@material/web";

export default {
  data() {
    return {
      items: [],
      activeTab: 0, // Index of the active tab
      loading: true,
      tabs: [
        { route: "/following", label: "Following" },
        { route: "/latest", label: "Latest" },
        { route: "/trending", label: "Trending" },
        // Add more tabs here
      ],
    };
  },
  components: {
    postList,
    bookmarksTabs,
    //  DotDrop,
    // NewPostTeaser,
  },

  methods: {
    async load() {
      try {
        //   const response = await fetch("/api/bots");
        const data = await window.API.me.getUserBookmarkedPosts();
        this.loading = false;
        this.items = data;
      } catch (error) {
        console.error(error);
      }
    },
    bookmarkRemoved(post) {
      //   alert("Bookmark removed" + post);
      this.items = this.items.filter((item) => item.id !== post);
    },
    /*
    navigate(newIndex) {
      this.$router.push(this.tabs[newIndex].route);
    },*/
    truncate(text2, length) {
      if (!text2) return;
      var text = String(text2);
      if (text.length > length) {
        return String(text).substring(0, length) + "...";
      }
      return text;
    },
    viewBot(bot) {
      // Code to view bot details
    },
    manageBot(bot) {
      // Code to manage bot settings
    },
  },
  mounted() {
    this.load();
    const tabIndex = this.tabs.findIndex((tab) => tab.route === this.$route.path);
    this.activeTab = tabIndex === -1 ? 0 : tabIndex;
  },
  watch: {
    "$route.path"() {
      const tabIndex = this.tabs.findIndex((tab) => tab.route === this.$route.path);
      this.activeTab = tabIndex === -1 ? 0 : tabIndex;
    },
  },
};
</script>

<style>
.botTable .description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.botTable td {
  vertical-align: middle;
}
</style>
